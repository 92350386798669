@import '../../styles/variables.css';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111;
  background-color: #000;
  opacity: 0.3;
  width: 100%;
  height: 100%;
}

.popup {
  width: 100%;
  top: 50%;
  transform: translate(0%, -50%);
  padding: 64px 16px;

  @media (--tablet-portrait-up) {
    left: 50%;
    transform: translate(-50%, -50%);
    width: 564px;
    padding: 64px;
  }
  background-color: var(--white);
  color: var(--black);
  z-index: 222;
  position: fixed;

  h3 {
    font-weight: 800;
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 16px;
  }
}

.buttons {
  display: flex;
  margin-top: 24px;
  width: 100%;
  gap: 10px;
  text-transform: uppercase;

  .infoButton {
    width: 50%;
    height: 51px;
    line-height: 51px;
    vertical-align: middle;
    text-align: center;
    border: 1px solid var(--black);
    color: var(--black);
  }

  .continueButton {
    padding: 14px 0px;
    width: 50%;
    height: 51px;
    cursor: pointer;
    font-weight: 900;
    font-size: 18px;
    line-height: 23px;
    text-transform: uppercase;
    color: var(--white);
    background-color: var(--black) ;
  }
}


