@import '../../styles/variables.css';

.container {
  min-height: 100vh;
  background-color: var(--apply-background);

  @media (--tablet-landscape-up) {
    overflow: hidden;
    background-color: var(--black);
  }
}
