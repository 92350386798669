@import '../../styles/variables.css';

.container {
  width: 100%;
}

.containerFloat {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-mobile-menu);
}

.containerSteps {
  display: none;

  @media (--tablet-landscape-up) {
    display: flex;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  @media (--tablet-landscape-up) {
    padding: 20px var(--container-padding);
  }
}

.logo {
  display: block;
  width: 124px;
  height: 26px;
}

.menu {
  display: flex;
}

.menuDark {
  .link {
    color: var(--black);
  }
}

.link {
  display: block;
  text-decoration: none;
  margin-left: 5px;
  padding: 5px;
  font-size: 12px;
  white-space: nowrap;
  color: var(--white);

  @media (--tablet-portrait-up) {
    margin-left: 25px;
    padding: 5px 8px;
    font-size: 14px;
  }
}

.linkActive {
  border-radius: 5px;
  color: var(--black);
  background-color: var(--white);
}
