@import '../../styles/variables.css';

.container {
  background-color: #131313;
}

.wrapper {
  @mixin max-width var(--container-max-width);
  padding: 27px var(--container-padding);

  @media (--tablet-landscape-up) {
    padding: 61px var(--container-padding) 37px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;

  &:last-child {
    @media (--tablet-landscape-up) {
      margin-top: 71px;
    }
  }
}

.rowCopy {
  flex-direction: column-reverse;

  @media (--tablet-landscape-up) {
    flex-direction: row;
  }
}

.btnWrap {
  flex-basis: 100%;
  margin-bottom: 30px;

  @media (--tablet-landscape-up) {
    flex-basis: initial;
    margin-bottom: 0;
  }
}

.scrollTopBtn {
  @mixin btn-reset;
  flex-shrink: 0;
  transform: rotate(45deg);
  width: 59px;
  height: 59px;
  border-radius: 100%;
  background-image: url('/img/svg/back-to-top.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 27px 27px;
  background-color: #393939;
  transition: transform 0.3s;
}

.rotateBtn .scrollTopBtn {
  transform: rotate(0deg);
}

.links {
  width: 50%;
  margin-bottom: 27px;

  @media (--tablet-landscape-up) {
    width: auto;
    margin-left: 10vw;
    margin-bottom: 0;
  }

  &:nth-child(2) {
    order: 1;
  }
  &:nth-child(3) {
    order: 3;
    @media (--tablet-landscape-up) {
      order: 2;
    }
  }
  &:nth-child(4) {
    order: 2;
    @media (--tablet-landscape-up) {
      order: 3;
    }
  }
  &:nth-child(5) {
    order: 4;
    @media (--tablet-landscape-up) {
      order: 4;
    }
  }
}

.link {
  display: block;
  @mixin font-family-title;
  @mixin font-boldest;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.47px;
  text-transform: uppercase;
  color: var(--white);

  &:not(:last-child) {
    margin-bottom: 9px;
  }
}

.copy {
  opacity: 0.4;
  @mixin font-family-title;
  @mixin font-medium;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.47px;
  text-transform: uppercase;
  color: var(--white);

  &:last-child {
    @media (--tablet-landscape-up) {
      margin-left: auto;
    }
  }
}
