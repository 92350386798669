@import '../../styles/variables.css';

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 40px 20px;
  text-align: center;
  color: #fff;
  background-color: #000;
  z-index: var(--z-vegeta);
}

.title {
  margin-bottom: 5px;
  font-size: 20px;
  text-transform: uppercase;
}

.text {
}

.link {
  padding: 5px 10px;
  color: #fff;
  border: 1px solid #fff;
  transition: color 0.2s, background-color 0.2s;

  &:hover {
    color: #000;
    background-color: #fff;
  }
}

.btn {
  @mixin btn-reset;
  position: absolute;
  top: 10px;
  right: 10px;
  height: 44px;
  width: 44px;
  background-image: var(--close-icon-white);
  background-repeat: no-repeat;
  background-position: center;
}
