@import '../styles/variables.css';

.container {
  position: relative;
  height: 100vh;

  &::before {
    content: '';
    display: none;
    width: 1000px;
    height: 2000px;
    transform: rotate(45deg);
    position: absolute;
    left: 45%;
    bottom: -800px;
    z-index: var(--z-default);

    @media (--tablet-landscape-up) {
      display: block;
    }
  }

  @media (--tablet-landscape-up) {
    overflow: visible;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 0 var(--container-padding);
  }
}

.mainContent {
  position: relative;
  height: 100%;

  @media (--tablet-landscape-up) {
    width: 50%;
  }

  @media (--desktop-up) {
    align-self: flex-start;
  }
}

.imageWrapper {
  position: relative;
  height: 100%;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: var(--z-default);

    @media (--tablet-landscape-up) {
      content: none;
    }
  }
}

.image {
  position: relative;
  display: block;
  width: 100%;
  @mixin fill-image;
  object-position: top center;

  @media (--tablet-landscape-up) {
    z-index: var(--z-content);
  }
}

.imageContent {
  position: absolute;
  bottom: 15px;
  right: 30px;
  text-align: right;
  z-index: var(--z-secondary);

  @media (--tablet-landscape-up) {
    display: none;
  }

  .button {
    margin-left: auto;
    color: var(--white);
    background-color: var(--black);
  }
}

.title {
  width: 280px;
  margin: 0 0 20px;
  @mixin font-regular;
  font-size: 28px;
  line-height: 1.2;
  color: var(--white);

  @media (--tablet-landscape-up) {
    width: auto;
    margin-bottom: 40px;
  }
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  padding: 6px 18px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  min-width: 120px;
  border: none;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  background-color: var(--white);
  color: var(--black);

  @media (--tablet-landscape-up) {
    min-width: 150px;
  }
}

.buttonIcon {
  margin-left: auto;
  transform: rotate(-90deg);
}

.countdownButton {
  margin-left: 35px;
  min-width: 140px;

  @media (--tablet-landscape-up) {
    display: none;
  }
}

.desktopContent {
  display: none;
  position: relative;
  width: 50%;
  padding-right: 5%;
  color: var(--white);
  z-index: var(--zindex-content);

  @media (--tablet-landscape-up) {
    display: block;
  }

  @media (--desktop-up) {
    padding-left: var(--container-padding);
    padding-right: 15%;
  }
}

.desktopText {
  margin: 0 0 40px;
  color: var(--white);

  p {
    font-size: 14px;
    line-height: 2;
    color: inherit;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
